import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GlobalTop from "../components/globalTop"

const WhyUs = () => {
  const [modal, setModal] = useState("")

  return (
    <Layout>
      {/* eslint-disable jsx-a11y/click-events-have-key-events */}
      {/* eslint-disable jsx-a11y/no-static-element-interactions */}
      <SEO title="Dlaczego my" />
      <GlobalTop title="Dlaczego my" />
      <section className="why-content">
        <div className="container">
          <h2 className="section--header text-center">
            10 powodów, dla których
            <strong>warto nam zaufać</strong>
          </h2>
          <div className="row align-items-center">
            <div className="col-md-4 text-center">
              <img
                className="img-fluid"
                src={require("../assets/images/why-content-image-1.svg")}
                alt=""
              />
            </div>
            <div className="col-md-6">
              <h4>Kochamy to co robimy</h4>
              <p>
                Wykonujemy zawód medyka z prawdziwą pasją. Dobro pacjentów jest
                dla nas szczególnie istotne, a standardy moralno-etyczne
                stanowią nasz priorytet.
              </p>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6 offset-md-2">
              <h4>Skupiamy się na potrzebach pacjenta</h4>
              <p>
                Głównym celem naszej firmy medycznej jest postawienie każdego
                pacjenta na zdrowe i silne nogi. Chcemy sprawić, by mógł czerpać
                radość ze stania, chodzenia, biegania i skakania.
              </p>
            </div>
            <div className="col-md-4 text-center">
              <img
                className="img-fluid"
                src={require("../assets/images/why-content-image-2.svg")}
                alt=""
              />
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-4 text-center">
              <img
                className="img-fluid"
                src={require("../assets/images/why-content-image-3.svg")}
                alt=""
              />
            </div>
            <div className="col-md-6">
              <h4>Specjaliści z różnych dziedzin</h4>
              <p>
                Nasz zespół składa się ze specjalistów różnych dziedzin. Ich
                efektywna współpraca/synergiczne działanie oparta na
                uzupełnianiu wiedzy i doświadczenia daje optymalne rezultaty.
              </p>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6 offset-md-2">
              <h4>Holistyczne podejście</h4>
              <p>
                Każdy przypadek traktujemy holistycznie. Nie tylko na podstawie
                objawów, ale też dogłębnego wywiadu z pacjentem diagnozujemy
                możliwe przyczyny dolegliwości, by następnie leczyć je
                wielopłaszczyznowo.
              </p>
            </div>
            <div className="col-md-4 text-center">
              <img
                className="img-fluid"
                src={require("../assets/images/why-content-image-4.svg")}
                alt=""
              />
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-4 text-center">
              <img
                className="img-fluid"
                src={require("../assets/images/why-content-image-5.svg")}
                alt=""
              />
            </div>
            <div className="col-md-6">
              <h4>Najnowszy sprzęt</h4>
              <p>
                Do działań diagnostycznych, leczniczych i rehabilitacyjnych
                wykorzystujemy najnowszy sprzęt producentów o międzynarodowej
                renomie.
              </p>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6 offset-md-2">
              <h4>Solidne doświadczenie</h4>
              <p>
                Wiedza medyczna i zdobyte doświadczenie pozwalają nam nieść
                skuteczną pomoc w przypadku dolegliwości zarówno układu
                kostno-stawowego, krwionośnego, jak i mięśniowego.
              </p>
            </div>
            <div className="col-md-4 text-center">
              <img
                className="img-fluid"
                src={require("../assets/images/why-content-image-6.svg")}
                alt=""
              />
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-4 text-center">
              <img
                className="img-fluid"
                src={require("../assets/images/why-content-image-7.svg")}
                alt=""
              />
            </div>
            <div className="col-md-6">
              <h4>Higiena pracy</h4>
              <p>
                Rygorystycznie przestrzegamy zasad higieny i bezpieczeństwa
                pracy. Dbamy o sterylność gabinetów i wykorzystywanych narzędzi,
                używamy jednorazowych materiałów.
              </p>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6 offset-md-2">
              <h4>Wysoki standard obsługi</h4>
              <p>
                Dbamy o wysoki standard obsługi. Nasz przyjazny system
                planowania wizyt oszczędza czas pacjentów, pozwalając na zdalne
                umówienie i dogodne przestawienie wizyty w razie potrzeby.
              </p>
              <br />
              <p>
                <strong>
                  W 2018 roku otrzymaliśmy na podstawie opinii pacjentów portalu
                  Znany Lekarz odznaczenie Lekarz Przyjazny Pacjentom
                </strong>
              </p>
              <img
                src={require("../assets/images/logo-znanylekarz.svg")}
                alt=""
              />
              <button
                onClick={e => setModal(true)}
                className="btn-custom btn-custom--purple btn-custom--small"
              >
                Wyświetl certyfikat
              </button>
            </div>
            <div className="col-md-4 text-center">
              <img
                className="img-fluid"
                src={require("../assets/images/why-content-image-8.svg")}
                alt=""
              />
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-4 text-center">
              <img
                className="img-fluid"
                src={require("../assets/images/why-content-image-9.svg")}
                alt=""
              />
            </div>
            <div className="col-md-6">
              <h4>Aktualna wiedza medyczna</h4>
              <p>
                Stale pogłębiamy naszą wiedzę medyczną, biorąc udział w
                specjalistycznych szkoleniach prowadzonych przez wiodące ośrodki
                szkoleniowe w kraju i za granicą.
              </p>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6 offset-md-2">
              <h4>Najnowsze technologie</h4>
              <p>
                W naszej pracy wykorzystujemy najnowsze technologie, które
                pomagają osiągnąć wysoką skuteczność i zapewniają pacjentowi
                komfort.
              </p>
            </div>
            <div className="col-md-4 text-center">
              <img
                className="img-fluid"
                src={require("../assets/images/why-content-image-10.svg")}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      {modal && (
        <div className="why-modal" onClick={e => setModal(false)}>
          <img
            className="img-fluid"
            src={require("../assets/images/why-certificate.jpg")}
            alt=""
          />
        </div>
      )}
    </Layout>
  )
}

export default WhyUs
